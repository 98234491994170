<template>
  <div class="container pb-5">

    <h2 class="pt-4">About VRTag</h2>

    <div class="py-2">
      This app is being developed by StS-ISONOR and Remark ANS.<br>
      More information can be found on the <a href="https://vrtag.no/" target="_blank">website</a>.
    </div>

    <div class="py-2">
      If you have an internet connection, the app will normally update to the latest version automatically.<br>
      You can check the latest version of the app by visiting this <a href="https://app.vrtag.no/#/version/" target="_blank">link</a>, which will help accelerate this process.<br>
      This requires an internet connection.<br>
    </div>

    <div class="py-2">
      App: {{ location }}<br>
      App version: {{ this.app.version }}<br> 
      Connected to: {{ this.app.api.server }}
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      location: window.location.host,
    }
  }
}
</script>